import React from "react";

export default ({
  name,
  label,
  type,
  value,
  initailValue,
  onChange,
  options,
  disabled,
  required,
  description,
}) => {
  return (
    <div className="form-group row m-1">
      <label label className="col-lg-4 col-form-label">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <div className="col-lg-6">
        {type === "select" ? (
          <div>
            <select
              key={name}
              className="form-control"
              defaultValue={initailValue}
              onChange={onChange}
              disabled={disabled}
            >
              {!initailValue && <option value={null}>please-select</option>}

              {options.map((opt) => (
                <option value={opt}>{opt}</option>
              ))}
            </select>
            <span className="text-danger">
              <small>
                {name === "mask" ? '*N30 or P10 Mask Size is "Free Size"' : ""}
              </small>
            </span>
          </div>
        ) : type === "text" || type === "date" ? (
          <div>
            <input
              key={name}
              className="form-control"
              type={type}
              name={name}
              value={value}
              onChange={onChange}
              defaultValue={initailValue}
              disabled={disabled}
              required={required}
            />
            <small>{description}</small>
          </div>
        ) : type === "textarea" ? (
          <textarea
            key={name}
            className="form-control"
            type={type}
            name={name}
            value={value}
            rows={"3"}
            onChange={onChange}
            defaultValue={initailValue}
            disabled={disabled}
            required={required}
          />
        ) : (
          {}
        )}
      </div>
    </div>
  );
};
