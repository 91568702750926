export default [
  {
    label: "Node",
    name: "node",
    type: "text",
    required: true,
  },
  {
    label: "Doctor ID",
    name: "doctorId",
    type: "text",
    required: true,
  },
  {
    label: "Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Fullname EN",
    name: "fullnameEn",
    type: "text",
  },
  {
    label: "Fullname TH",
    name: "fullnameTh",
    type: "text",
  },
  {
    label: "License Id",
    name: "licenseId",
    type: "text",
  },
  {
    label: "Designation",
    name: "designation",
    type: "text",
  },
  {
    label: "Doctor Type",
    name: "doctorType",
    type: "select",
    options: ["Sleep Physician", "Other"],
  },
  {
    label: "Token",
    name: "accessToken",
    type: "text",
  },
  {
    label: "Email",
    name: "email",
    type: "text",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: ["Available", "Unavailable"],
  },
  {
    label: "Note",
    name: "note",
    type: "textarea",
    // options: ["Available", "Unavailable"],
  },
];
