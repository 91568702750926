import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { EditForm } from "./EditForm";

const DetailModal = (props) => {
  const inputRef = useRef({
    serialNumber: null,
    brand: null,
    model: null,
    description: null,
    type: null,
    vendor: null,
    deviceNo: null,
    lotNo: null,
    incomingDate: null,
    note: null,
    status: null,
    sales: null,
  });

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };
  const handleOnHideModal = () => {
    props.hide();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      serialNumber: inputRef.current.serialNumber
        ? inputRef.current.serialNumber
        : props.cpap.serialNumber,
      brand: inputRef.current.brand ? inputRef.current.brand : props.cpap.brand,
      model: inputRef.current.model ? inputRef.current.model : props.cpap.model,
      description:
        inputRef.current.description != undefined
          ? inputRef.current.description
          : props.cpap.description,
      type: inputRef.current.type ? inputRef.current.type : props.cpap.type,
      vendor: inputRef.current.vendor
        ? inputRef.current.vendor
        : props.cpap.vendor,
      deviceNo:
        inputRef.current.deviceNo != undefined
          ? inputRef.current.deviceNo
          : props.cpap.deviceNo,
      lotNo:
        inputRef.current.lotNo != undefined
          ? inputRef.current.lotNo
          : props.cpap.lotNo,
      incomingDate: inputRef.current.incomingDate
        ? new Date(inputRef.current.incomingDate)
        : props.cpap.incomingDate,
      note:
        inputRef.current.note != undefined
          ? inputRef.current.note
          : props.cpap.note,
      status:
        props.cpap.status == "ขาย"
          ? props.cpap.status
          : inputRef.current.status != undefined
          ? inputRef.current.status
          : props.cpap.status,

      sales:
        props.cpap.status == "ขาย"
          ? props.cpap.sales
          : inputRef.current.status == "ว่าง"
          ? ""
          : inputRef.current.sales != undefined
          ? inputRef.current.sales
          : props.cpap.sales,
      updatedAt: new Date(),
    };
    props.onEditSubmit(props.cpap.id, formData);
    props.hide();
  };

  return (
    <Fragment>
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditForm onChange={handleChange} initailValues={props.cpap} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default DetailModal;
